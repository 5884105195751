import React, { Suspense } from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default function Layout({ showAlert }) {

    const location = useLocation()
    const isAdminPath = location.pathname.toLowerCase().startsWith('/admin');

    return (
        <Box overflowY='auto' overflowX='hidden' >

            {isAdminPath ? (
                <>
                    <Navbar />
                    <Suspense fallback={
                        <Flex w='100%' h='calc(100vh - 83px)' bgColor='#ffffff47' justifyContent='center' alignItems='center'>
                            <Spinner size='lg' />
                        </Flex>
                    }>

                        <Flex p={{ xl: '0px 20px', lg: '0px 20px', md: '0px 20px', sm: '0px 5px', base: '0px' }} zIndex='-1'>
                            <Box overflow='hidden' w='100%' p='20px 10px'>
                                <Outlet />
                            </Box>
                        </Flex>
                    </Suspense>
                </>
            ) : (
                <>
                    <Navbar showAlert={showAlert} />
                    <Flex p='0px 0px' zIndex='-1' h={'calc(100vh - 83px)'} >
                        <Sidebar />
                        <Suspense fallback={
                            <Flex w='100%' h={'calc(100vh - 83px)'} justifyContent='center' alignItems='center'>
                                <Spinner size='lg' />
                            </Flex>
                        }>
                            <Box overflow='auto' w='100%' p='15px 10px' background='#fff'>
                                <Outlet />
                            </Box>
                        </Suspense>
                    </Flex>
                </>
            )}

        </Box>
    )
}
