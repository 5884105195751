import React, { lazy } from "react";
import { useToast } from "@chakra-ui/react";
import Layout from "./components/Layout";
import './App.css'
import { Route, Routes } from "react-router-dom";

const Dashboard = lazy(() => import("./components/Admin/Dashboard"))
const Jobs = lazy(() => import("./components/Admin/Jobs"))
const CreateJob = lazy(() => import("./components/Admin/CreateJob"))
const ViewJob = lazy(() => import("./components/Admin/Viewjob"))
const Jobsheet = lazy(() => import("./components/Admin/Jobsheet"))
const CreateBackupDrive = lazy(() => import("./components/Admin/CreateBackupDrive"))
const AddCustomer = lazy(() => import("./components/Customer/AddCustomer"))
const CustomerProfile = lazy(() => import("./components/Customer/CustomerProfile"))
const CustomerList = lazy(() => import("./components/Customer/CustomerList"))
const Leads = lazy(() => import("./components/Admin/Leads"))
const LeadProfile = lazy(() => import("./components/Admin/LeadProfile"))
const CreateLeads = lazy(() => import("./components/Admin/CreateLeads"))
const PickUpDropList = lazy(() => import("./components/Admin/PickUpDropList"))
const BackUpDrivesList = lazy(() => import("./components/Admin/BackupDriveList"))
const AddPickupDrop = lazy(() => import("./components/Admin/AddPickupDrop"))
const EditBackupDrive = lazy(() => import("./components/Admin/EditbackupDrive"))
const Business = lazy(() => import("./components/Setting/Business/Business"))
const JobSetting = lazy(() => import("./components/Setting/Job/JobSetting"))
const GeneralSetting = lazy(() => import("./components/Setting/General/GeneralSetting"))
const Quotation = lazy(() => import("./components/Admin/Quotation"))
const Invoice = lazy(() => import("./components/Admin/Invoice"))
const Employee = lazy(() => import("./components/Setting/Employee/Employee"))
const AddEmployee = lazy(() => import("./components/Setting/Employee/AddEmployee"))
const EmployeeProfile = lazy(() => import("./components/Setting/Employee/EmployeeProfile"))
const BackupDriveDetails = lazy(() => import("./components/Admin/BackupDriveDetails"))
const AddDrop = lazy(() => import("./components/Admin/AddDrop"))
const Login = lazy(() => import('./components/Admin/AdminLogin'))
const DeliveryReceipt = lazy(() => import('./components/Admin/DeliveryReceipt'))
const PaymentReceipt = lazy(() => import('./components/Admin/PaymentReceipt'));
const CustomerDashboard = lazy(() => import('./components/Admin/CustomerDashboard'));
const Authentication = lazy(() => import("./components/Admin/Authentication"))
const ForgotPassword = lazy(() => import("./components/ForgotPassword"))
const ChangePassword = lazy(() => import("./components/ChangePassword"))
const RemoteSession = lazy(() => import("./components/Admin/RemoteSession"))
const UserProfile = lazy(() => import("./components/Admin/UserProfile"))
const Reports = lazy(() => import("./components/Admin/Reports"))

// const Home = lazy(() => import('./Home'))
function App() {

  const toast = useToast();
  const statusMap = {
    success: "success",
    danger: "error",
    warning: "warning",
    info: "info"
  };
  const showAlert = (message, type) => {

    toast({
      title: message,
      status: statusMap[type] || "error",
      duration: 4000,
      isClosable: true,
      position: 'top'
    })
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Login showAlert={showAlert} />} />
        <Route path="/admin/" element={<Layout showAlert={showAlert} />}>
          <Route path="dashboard" element={<Dashboard showAlert={showAlert} />} />
          <Route path="jobs" element={<Jobs showAlert={showAlert} />} />
          <Route path="createjob" element={<CreateJob showAlert={showAlert} />} />
          <Route path="jobsheet/:id" element={<Jobsheet showAlert={showAlert} />} />
          <Route path="viewJob/:id" element={<ViewJob showAlert={showAlert} />} />
          <Route path="createbackupdrive" element={<CreateBackupDrive showAlert={showAlert} />} />
          <Route path="customers" element={<CustomerList showAlert={showAlert} />} />
          <Route path="addcustomer" element={<AddCustomer showAlert={showAlert} />} />
          <Route path="customerprofile/:id" element={<CustomerProfile showAlert={showAlert} />} />
          <Route path="leads" element={<Leads showAlert={showAlert} />} />
          <Route path="leadprofile/:id" element={<LeadProfile showAlert={showAlert} />} />
          <Route path="createlead" element={<CreateLeads showAlert={showAlert} />} />
          <Route path="pickupdrop" element={<PickUpDropList showAlert={showAlert} />} />
          <Route path="backupdrive" element={<BackUpDrivesList showAlert={showAlert} />} />
          <Route path="addpickupdrop" element={<AddPickupDrop showAlert={showAlert} />} />
          <Route path="adddrop" element={<AddDrop showAlert={showAlert} />} />
          <Route path="editbackup/:id" element={<EditBackupDrive showAlert={showAlert} />} />
          <Route path="quotation/:id" element={<Quotation showAlert={showAlert} />} />
          <Route path="invoice/:id" element={<Invoice showAlert={showAlert} />} />
          <Route path="backupdrivedetails/:id" element={<BackupDriveDetails showAlert={showAlert} />} />
          <Route path="deliveryreceipt/:id" element={<DeliveryReceipt showAlert={showAlert} />} />
          <Route path="paymentreceipt/:id" element={<PaymentReceipt showAlert={showAlert} />} />
          <Route path="userprofile" element={<UserProfile showAlert={showAlert} />} />
          <Route path="customerdashboard" element={<CustomerDashboard showAlert={showAlert} />} />
          <Route path="reports" element={<Reports showAlert={showAlert} />} />
        </Route>

        <Route path="/setting/" element={<Layout showAlert={showAlert} />}>
          <Route path="business" element={<Business showAlert={showAlert} />} />
          <Route path="job_setting" element={<JobSetting showAlert={showAlert} />} />
          <Route path="general_setting" element={<GeneralSetting showAlert={showAlert} />} />
          <Route path="Employee_setting" element={<Employee showAlert={showAlert} />} />
          <Route path="addemployee" element={<AddEmployee showAlert={showAlert} />} />
          <Route path="employeeprofile/:id" element={<EmployeeProfile showAlert={showAlert} />} />
        </Route>

        <Route path="/authentication/:details" element={<Authentication showAlert={showAlert} />}></Route>
        <Route path="/forgotpassword/:link" element={<ForgotPassword showAlert={showAlert} />}></Route>
        <Route path="/forgotpassword/changepassword/:link" element={<ChangePassword showAlert={showAlert} />}></Route>
        <Route path="/remotesession/:details" element={<RemoteSession showAlert={showAlert} />}></Route>

      </Routes>
    </>
  );
}

export default App;
