import React, { useContext, useEffect, useState } from "react";
import {
  HStack,
  Flex,
  Box,
  Image,
  Text,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  useDisclosure,
  Button,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Logo from "../assets/Admin/logo.png";
import { FaRegBell } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { Avatar } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { JobContext } from "../context/JobContext";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from "@chakra-ui/icons";

function Navbar({ showAlert }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isChangeOpen, onOpen: onChangeOpen, onClose: onChangeClose } = useDisclosure();
  const {
    getEmployeeNotificationsById,
    empNotifications,
    decryptOnly,
    groupNotificationsByDay,
    formatDate, getCookie
  } = useContext(JobContext);



  // const encrypted = decryptOnly(localStorage.getItem("user"));


  const googleAuthUser = {
    user_id: getCookie("id"),
    user_type: getCookie("role"),
  };
  // const encryptedData = encrypt(
  //   JSON.stringify({
  //     user_id: googleAuthUser.user_id,
  //     user_type: googleAuthUser.user_type,
  //   })
  // );
  // localStorage.setItem("user", encryptedData);

  // console.log(localStorage.getItem("user"))
  // Store the encrypted user in localStorage

  const encrypted = localStorage.getItem("user") ? decryptOnly(localStorage.getItem("user")) : googleAuthUser;


  useEffect(() => {
    getEmployeeNotificationsById(encrypted?.user_id);
    fetchEmployeeDetails();
    // saveEncryptedUserData();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const isAdminPath = location.pathname.toLowerCase().startsWith("/admin");
  const unregisterServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
        console.log("Service Worker unregistered");
      }
    }
  };


  const sortedNotification = groupNotificationsByDay(empNotifications);
  const seenNotifications = groupNotificationsByDay(
    empNotifications?.filter((element) => element.seen === "1")
  );
  const unSeenNotifications = groupNotificationsByDay(
    empNotifications?.filter((element) => element.seen === "0")
  );

  const markSeen = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markEmployeeNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: getCookie("token") || localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notification_id: id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      getEmployeeNotificationsById(encrypted?.user_id);
    } else {
      showAlert(json.error, "error");
    }
  };

  const [loading, setLoading] = useState(false);
  const markAllSeen = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/notification/markAllEmployeeNotificationSeen`,
      {
        method: "POST",
        headers: {
          Authorization: getCookie("token") || localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employee_id: encrypted?.user_id }),
      }
    );
    const json = await response.json();
    if (json.success) {
      setLoading(false);
      getEmployeeNotificationsById(encrypted?.user_id);
    } else {
      setLoading(false);
      showAlert(json.error, "error");
    }
  };
  // console.log(encrypted?.user_id)
  const [userDetails, setUserDetails] = useState(null)
  const fetchEmployeeDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/employee/employee/getEmployeeById?employee_id=${encrypted?.user_id}`,
        {
          headers: { Authorization: getCookie("token") || localStorage.getItem("token") },
        }
      );
      const json = await response.json();
      if (json.success) {
        setUserDetails(json.employees);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  const NotificationElement = ({ element, onClose }) => {
    return (
      <Box
        bgColor={element.seen === "0" ? "#5a82dc3b" : "#fafbff"}
        p={2}
        borderTop="1px solid #ccc"
        borderBottom="1px solid #ccc"
        cursor="pointer"
        _hover={{ backgroundColor: "#0000001c" }}
        transition="0.25s"
        onClick={() => markSeen(element.id)}
      >
        <Flex gap={2} px={3} position="relative" alignItems="center">
          <Box>
            <Avatar size="md" name="Tejas Chavan" />
          </Box>
          <Box>
            <Text fontSize="14px" fontWeight="500">
              {element?.topic}
            </Text>
            <Text fontSize="12px">{element?.content}</Text>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize="11px" color="#807f7f">
                {formatDate(element?.created_at, true)}
              </Text>
              {element.links ? <Link to={element.links} onClick={onClose}>
                <Button size='xs' rightIcon={<ExternalLinkIcon fontSize='13px' />} colorScheme='blackAlpha' variant='outline'>
                  Open
                </Button>
              </Link> : <Text w='max-content'></Text>}
            </Flex>
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="-4px"
            width="7px"
            height="7px"
            borderRadius={5}
            bgColor={element.seen === "0" ? "#5a82dc" : "transparent"}
          ></Box>
        </Flex>
      </Box>
    );
  };

  const [creds, setCreds] = useState({
    current_password: "",
    new_password: "",
    confirm_password: ""
  })

  const handleOnChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value })
  }

  const handleChangePassword = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/admin/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employee_id: userDetails?.id, current_password: creds.current_password, new_password: creds.new_password })
      })
      const json = await response.json();
      if (json.success) {
        showAlert(json.success, 'success')
        setLoading(false);
        onChangeClose();
        setCreds({
          current_password: "",
          new_password: "",
          confirm_password: ""
        })
      } else {
        showAlert(json.error, 'error')
        setLoading(false);
      }
    } catch (error) {
      showAlert('Internal server error', 'error')
      setLoading(false);
    }
  }

  return (
    <>
      <HStack
        p={{
          xl: "0px 20px",
          lg: "0px 20px",
          md: "0px 20px",
          sm: "0px 5px",
          base: "0px",
        }}
        justifyContent="space-between"
        w="100%"
        height="83px"
        bg="#fff !important"
        zIndex={111}
        boxShadow={isAdminPath === "/admin" ? " 0 0 3px #ccc;" : null}
      >
        <Flex
          w="100%"
          gap={{ xl: 35, lg: 25, md: 13, sm: 5, base: 2 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            w="100%"
            h="100%"
            justifyContent='space-between'
            alignItems="center"
            gap={{ xl: 3, lg: 3, md: 2, sm: 5, base: 2 }}
          >
            <Box m="15px" mr="0px" ml={{ xl: "10px", lg: "10px", md: "10px" }}>
              <Image
                src={Logo}
                alt="logo"
                w={{
                  xl: "150px",
                  lg: "150px",
                  md: "150px",
                  sm: "150px",
                  base: "150px",
                }}
                h="auto"
              />
            </Box>
            <Flex justifyContent='space-evenly'
              alignItems="center">
              <Link to='/admin/dashboard'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  bgColor={location.pathname === '/admin/dashboard' ? '#007bff29' : 'transparent'}
                  borderRadius='4px'
                  p='4px 12px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/dashboard' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    // _hover={{
                    //   bgColor: '#007bff29',
                    //   borderRadius:'2px',
                    //   boxShadow:'0 0 5px #007bff59',
                    //   color:'#007bff'
                    // }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Dashboard
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/jobs'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/jobs' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/jobs' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Jobs
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/customers'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/customers' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/customers' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Customers
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/backupdrive'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/backupdrive' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    w="max-content"
                    color={location.pathname === '/admin/backupdrive' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Backup Drives
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/leads'>
                <Box
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/leads' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    color={location.pathname === '/admin/leads' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Leads
                  </Text>
                </Box>
              </Link>
              <Link to='/admin/pickupdrop'>
                <Box
                  w="max-content"
                  display={{
                    xl: "block",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    base: "none",
                  }}
                  p='4px 12px'
                  bgColor={location.pathname === '/admin/pickupdrop' ? '#007bff29' : 'transparent'}
                  borderRadius='3px'
                  _hover={{
                    bgColor: '#007bff29',
                    // boxShadow:'0 0 5px #007bff59',
                    color: '#007bff'
                  }}
                  sx={{
                    '&:hover p': {
                      color: '#007bff'
                    }
                  }}
                  transition='0.25s'
                >
                  <Text
                    w="max-content"
                    color={location.pathname === '/admin/pickupdrop' ? '#007bff' : "#000000"}
                    fontSize={{
                      xl: "15px",
                      lg: "13px",
                      md: "13px",
                      sm: "15px",
                      base: "15px",
                    }}
                    fontWeight="500"
                    cursor="pointer"
                  >
                    Pickup/Drops
                  </Text>
                </Box>
              </Link>

            </Flex>
          </Flex>


          <Flex
            // justifyContent='center'
            alignItems="center"
            // w='100%'
            mr="10px"
          // gap={{ xl: 25, lg: 25, md: 13, sm: 5, base: 2 }}
          >
            <Box
              mx="10px"
              minW={{
                xl: "250px",
                lg: "150px",
                md: "150px",
                sm: "250px",
                base: "250px",
              }}
              display={{
                xl: "block",
                lg: "block",
                md: "none",
                sm: "none",
                base: "none",
              }}
            >
              {/* <Text color='#000000' fontSize='15px' lineHeight='18.75px' fontWeight='500' cursor='pointer'> Settings</Text> */}
              <Stack spacing={4}>
                <InputGroup bg="#F1F5F7" borderRadius="10px">
                  <InputLeftAddon border="0" px="10px" bg="#F1F5F7">
                    {" "}
                    <IoSearchOutline fontSize="20" />{" "}
                  </InputLeftAddon>
                  <Input
                    type="text"
                    px="5px"
                    placeholder="search"
                    border="0"
                    outline="none"
                    _focus={{ boxShadow: "none", border: "0px" }}
                    borderRadius="10px"
                  />
                </InputGroup>
              </Stack>
            </Box>

            <Menu>
              <Tooltip label={`${userDetails?.name} (${userDetails?.role})`} fontSize='12px'>
                <MenuButton
                  as={Button}
                  w="max-content"
                  p={0}
                  h="max-content"
                  minW="max-content"
                  borderRadius={50}
                >
                  <Box
                    display={{
                      xl: "block",
                      lg: "block",
                      md: "none",
                      sm: "none",
                      base: "none",
                    }}
                  >
                    <Avatar
                      size="sm"
                      name={userDetails?.name}
                      src={userDetails?.profile_photo ? userDetails?.profile_photo : ''}
                    />
                  </Box>
                </MenuButton>
              </Tooltip>
              <MenuList>
                <MenuItem fontSize="14px" onClick={() => navigate('/admin/reports')}>
                  <Link to={`/admin/reports`}>
                    Reports
                  </Link>
                </MenuItem>
                <MenuItem
                  as='a'
                  href="/#/setting/business"
                  fontSize="14px"
                  onClick={(e) => { e.preventDefault(); navigate(`/setting/business`) }}
                >
                  <Link to='/setting/business'>
                    <Text>Settings</Text>
                  </Link>
                </MenuItem>
                <MenuItem
                  as='a'
                  href={`/#/admin/userprofile/${encrypted?.user_id}`}
                  fontSize="14px"
                  onClick={(e) => { e.preventDefault(); navigate(`/admin/userprofile/${encrypted?.user_id}`) }}
                >
                  <Link to='/setting/business'>
                    <Text>Profile</Text>
                  </Link>
                </MenuItem>
                <MenuItem
                  fontSize="14px"
                  onClick={onChangeOpen}
                >
                  <Link to='/setting/business'>
                    <Text>Change Password</Text>
                  </Link>
                </MenuItem>
                <MenuItem
                  fontSize="14px"
                  onClick={() => {
                    navigate("/");
                    localStorage.clear();
                    unregisterServiceWorker();
                  }}
                >
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
            <Tooltip label='Notifications' fontSize='12px'>
              <Box
                ml="10px"
                display={{
                  xl: "block",
                  lg: "block",
                  md: "none",
                  sm: "none",
                  base: "none",
                }}
              >
                <FaRegBell fontSize="20" onClick={onOpen} cursor="pointer" />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Notifications</DrawerHeader>

            <DrawerBody p="0px" bgColor="#fafbff">
              <Tabs position="relative" variant="unstyled">
                <Flex justifyContent="space-between" bgColor="#fff">
                  <TabList px={3}>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      All
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Unread
                    </Tab>
                    <Tab fontSize="13px" _active={{ color: "#5a80dc" }}>
                      Read
                    </Tab>
                  </TabList>
                  <Button
                    fontSize="12px"
                    padding="0px 8px"
                    height="auto"
                    color="#5a80dc"
                    bg="transparent"
                    onClick={markAllSeen}
                    isLoading={loading}
                  >
                    Mark all as read
                  </Button>
                </Flex>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels height="100%">
                  <TabPanel px={0} bgColor="#fafbff">
                    {sortedNotification.length !== 0 ? (
                      sortedNotification?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                              onClose={onClose}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {unSeenNotifications.length !== 0 ? (
                      unSeenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel px={0} bgColor="#fafbff">
                    {seenNotifications.length !== 0 ? (
                      seenNotifications?.map((element, index) => (
                        <Box key={index}>
                          <Text
                            fontSize="12px"
                            px={1}
                            mt={3}
                            mb={2}
                            letterSpacing="1.5px"
                          >
                            {element.day.toUpperCase()}
                          </Text>
                          {element.notifications?.map((element, index) => (
                            <NotificationElement
                              key={index}
                              element={element}
                            />
                          ))}
                        </Box>
                      ))
                    ) : (
                      <Text textAlign="center" fontSize="13px">
                        Nothing here
                      </Text>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Modal isOpen={isChangeOpen} onClose={onChangeClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody as='form'>
              <FormControl>
                <FormLabel fontSize="13px" mb='0px'>
                  Current Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="password"
                  fontSize="14px"
                  alignContent={"center"}
                  placeholder="Password"
                  onChange={handleOnChange}
                  name="current_password"
                  value={creds.current_password}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize="13px" mb='0px'>
                  New Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  placeholder="New password"
                  type="password"
                  onChange={handleOnChange}
                  value={creds.new_password}
                  name="new_password"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize="13px" mb='0px'>
                  Confirm Password
                  <Text as="span" color="red" fontSize="12" ml={1}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  type="password"
                  placeholder="Re-type password"
                  value={creds.confirm_password}
                  onChange={handleOnChange}
                  name="confirm_password"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter gap={2}>
              <Button
                onClick={() => {
                  onChangeClose();
                  setCreds({
                    current_password: "",
                    new_password: "",
                    confirm_password: ""
                  })
                }}
                color="#ff5b5b"
                border="1px solid #ff5b5b"
                fontSize="14px"
                background="transparent"
                _hover={{ color: "#ff5b5b", background: "transparent" }}
              >
                Cancel
              </Button>
              <Button

                fontSize="14px"
                bgColor="#030228"
                color="white"
                onClick={handleChangePassword}
                isLoading={loading}
                _hover={{ color: "white", background: "#030228" }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    </>
  );
}

export default Navbar;
